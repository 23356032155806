import FontFaceObserver from 'fontfaceobserver';
import imagesLoaded from 'imagesloaded';
import addClass from '@sparkle/js/fn/attributes/addClass';
import attr from '@sparkle/js/fn/attributes/attr';
import hasClass from '@sparkle/js/fn/attributes/hasClass';
import hide from '@sparkle/js/fn/attributes/hide';
import removeClass from '@sparkle/js/fn/attributes/removeClass';
import show from '@sparkle/js/fn/attributes/show';
import select from '@sparkle/js/fn/select/select';
import selectAll from '@sparkle/js/fn/select/selectAll';
import each from '@sparkle/js/helpers/collection/each';
import setProps from '@sparkle/js/helpers/object/setProps';
import scroll from '@sparkle/js/methods/browser/scroll';
import dataName from '@sparkle/js/helpers/utils/dataName';
import sleep from '@sparkle/js/methods/utils/sleep';
import DomManager from '@sparkle/js/utilities/DomManager';
import EventsManager from '@sparkle/js/utilities/EventsManager';
import Morph from '@sparkle/js/utilities/Morph';
import Tweener from '@sparkle/js/utilities/Tweener';

/*
 * Intro functionalities
 *
 * @author Christophe Meade
 * @copyright 2020-present Christophe Meade
 *
 * @param {Node} el
 * @param {Object} options
 */
const Intro = function (el, options) {
    const that = this;

    // Options
    setProps(options, {
        selector: Intro.SELECTOR
    });

    // Modifiers
    that.modifiers = {
        vInit: 'v--init',
        vActive: 'v--active',
        vAnimating: 'v--animating'
    };

    // Selectors
    that.selectors = {
        go: `${options.selector}__go`,
        scroll: `${options.selector}__scroll`,
        section: `${options.selector}__section`,
        sectionNav: `${options.selector}__section-nav`,
        sectionParagraph: `${options.selector}__section-paragraph`,
        sectionTitle: `${options.selector}__section-title`,
        sectionTitleH: `${options.selector}__section-title-h`,
        reveal: `${options.selector}__reveal`,
        imgOverlay: `${options.selector}__img-overlay`,
        imgOverlayAnimation: `${options.selector}__img-overlay-animation`,
        imgOverlayWaves: `${options.selector}__img-overlay-waves`,
        slideshow: `${options.selector}__slideshow`,
        slideshowHero: `${options.selector}__slideshow-hero`
    };

    // Global variables
    that.el = el;
    that.options = options;
    that.events = new EventsManager();

    // DOM
    that.dom = new DomManager();
    that.dom.set({
        slideshow: () => select(that.selectors.slideshow, el),
        slideshowHeros: () => selectAll(that.selectors.slideshowHero, that.dom.$('slideshow')),
        imgOverlay: () => select(that.selectors.imgOverlay, el),
        imgOverlayAnimation: () => select(that.selectors.imgOverlayAnimation, that.dom.$('imgOverlay')),
        imgOverlayWaves: () => select(that.selectors.imgOverlayWaves, el),
        waveFlat: () => select('#flat', that.dom.$('imgOverlayWaves')),
        waveOut: () => select('#wave-3', that.dom.$('imgOverlayWaves')),
        waveIn: () => select('#wave-1', that.dom.$('imgOverlayWaves')),
        loader: () => select(`${options.selector}__loader`, el),
        extra: () => select(`${options.selector}__extra`)
    });

    // Init
    that.init();
};

/**
 * Init
 */
Intro.prototype.init = async function () {
    const that = this;

    // Section
    that.section = 'innovate';

    // Instance
    that.$section = select(`#${that.section}`, that.el);
    that.$img = select(`#${that.section}-img`, that.el);

    // Listener - Scroll
    each(selectAll(that.selectors.scroll), $scroll => {
        that.events.on($scroll, 'click', e => {
            e.preventDefault();
            scroll(attr($scroll, `${dataName(that.options.selector)}:scroll`), { speed: 800 });
        });
    });

    // Listener - Go
    each(selectAll(that.selectors.go), elGo => {
        that.events.on(elGo, 'click', e => {
            e.preventDefault();
            that.go(attr(elGo, `${dataName(that.options.selector)}:go`));
        });
    });

    // Fct - Loaded Fonts
    const loadedFonts = async () => {

        // Font Observers
        const observers = [];
        observers.push(new FontFaceObserver('matter').load(null, 5000));
        observers.push(new FontFaceObserver('sora').load(null, 5000));

        // Fonts Loaded
        await Promise.all(observers);

        return;
    };

    // Fct - Loaded Image
    const loadedImg = async () => {
        await new Promise(resolve => {
            imagesLoaded(that.$img, () => {
                resolve();
            });
        });
        return;
    };

    // Fct - Loader -> Hide
    const loaderHide = async () => {
        hide(that.dom.$('loader'));
        return;
    };

    // Proceed
    await Promise.all([loadedFonts(), loadedImg()]);
    await loaderHide();

    // Show section
    const $sectionIn = select(`#${that.section}`, that.el);
    const $imgIn = select(`#${that.section}-img`, that.el);
    that.showSection($sectionIn, $imgIn);
};

/**
 * Go to section
 *
 * @param {String} section
 */
Intro.prototype.go = async function (section) {
    const that = this;

    // Elements In & Out
    const $sectionOut = that.$section;
    const $imgOut = that.$img;
    const $sectionIn = select(section, that.el);
    const $imgIn = select(`${section}-img`, that.el);

    // Hide/Show Sections
    if (that.section !== section) {
        that.section = section;
        await that.hideSection($sectionOut, $imgOut);
        await that.showSection($sectionIn, $imgIn);
    }
};

/**
 * Hide section
 *
 * @param {String} section
 */
Intro.prototype.hideSection = async function ($sectionOut, $imgOut) {
    const that = this;

    // Elements
    const $sectionOutNav = select(that.selectors.sectionNav, $sectionOut);
    const $sectionOutTxt = select(that.selectors.sectionTxt, $sectionOut);
    const $sectionOutParagraph = select(that.selectors.sectionParagraph, $sectionOut);
    const $sectionOutTitle = select(that.selectors.sectionTitle, $sectionOut);

    // Text
    new Tweener({ speed: 550, ease: 'power2.out' })
        .add($sectionOutNav, {
            opacity: { to: 0 },
            scale: { to: 0.8, ease: 'power1.in' }
        })
        .add($sectionOutParagraph, {
            opacity: { to: 0 },
            scale: { to: 0.8, ease: 'power1.in' }
        })
        .add($sectionOutTitle, {
            opacity: { to: 0 },
            scale: { to: 0.8, ease: 'power1.in' }
        })
        .play();

    // Img
    new Tweener({ speed: 600, ease: 'power1.in' })
        .add(that.dom.$('imgOverlayAnimation'), {
            scaleX: { from: 0, to: 1 }
        })
        .play();

    await new Morph({ speed: 300, ease: 'sine.inOut' })
        .add(that.dom.$('imgOverlayAnimation'), {
            from: that.dom.$('waveFlat'),
            to: that.dom.$('waveOut')
        })
        .onStart(() => {
            show(that.dom.$('imgOverlay'));
        })
        .play();

    await new Morph({ speed: 300, ease: 'sine.inOut' })
        .add(that.dom.$('imgOverlayAnimation'), {
            to: that.dom.$('waveFlat')
        })
        .play();

    Tweener.set($sectionOut, { opacity: 0 });
    Tweener.clear([$sectionOutParagraph, $sectionOutTitle, $sectionOutNav], ['scale', 'opacity']);
    hide($imgOut);

    removeClass($sectionOut, that.modifiers.vActive);

    return;
};

/**
 * Show section
 *
 * @param {String} section
 */
Intro.prototype.showSection = async function ($sectionIn, $imgIn) {
    const that = this;

    // Elements
    const $sectionInNav = select(that.selectors.sectionNav, $sectionIn);
    const $sectionInTxt = select(that.selectors.sectionTxt, $sectionIn);
    const $sectionInParagraph = select(that.selectors.sectionParagraph, $sectionIn);
    const $sectionInParagraphP = select('p', $sectionInParagraph);
    const $sectionInParagraphReveal = select(that.selectors.reveal, $sectionInParagraph);
    const $sectionInTitle = select(that.selectors.sectionTitle, $sectionIn);
    const $sectionInTitleH = select(that.selectors.sectionTitleH, $sectionInTitle);
    const $sectionInTitleReveal = select(that.selectors.reveal, $sectionInTitle);

    that.$section = $sectionIn;
    that.$img = $imgIn;
    Tweener.set([$sectionIn, $sectionInTitleH, $sectionInParagraphP, $sectionInNav], { opacity: 0 });

    // Classes
    addClass($sectionIn, that.modifiers.vActive);
    addClass($imgIn, that.modifiers.vActive);

    // Text
    new Tweener({ speed: 400, ease: 'power2.out' })
        .add([$sectionInTitleReveal, $sectionInParagraphReveal], {
            scaleY: { from: 0, to: 1 },
            y: { from: '-50%', to: '0%' }
        })
        .onStart(() => {
            Tweener.set($sectionIn, { opacity: 1 });
            show([$sectionInTitleReveal, $sectionInParagraphReveal]);
            removeClass(that.el, that.modifiers.vInit);
        })
        .play()
        .then(() => {

            new Tweener({ speed: 400, ease: 'power1.out' })
                .add([$sectionInTitleReveal, $sectionInParagraphReveal], {
                    scaleY: { to: 0 },
                    y: { to: '50%' }
                })
                .add($sectionInNav, {
                    opacity: { from: 0, to: 1, delay: 100, ease: 'power2.out' },
                    x: { from: 10, to: 0, delay: 100, ease: 'power2.in' }
                })
                .onStart(() => {
                    show($sectionInNav);
                    Tweener.set([$sectionInTitleH, $sectionInParagraphP], { opacity: 1 });
                })
                .play();
        });

    new Tweener({ speed: 700, ease: 'power1.out' })
        .add(that.dom.$('imgOverlayAnimation'), {
            scaleX: { from: 1, to: 0 }
        })
        .add(select(that.selectors.go, $imgIn), {
            scale: { from: 1.1, to: 1 }
        })
        .onStart(() => {
            show($imgIn);
        })
        .play();

    await new Morph({ speed: 350, ease: 'sine.inOut' })
        .add(that.dom.$('imgOverlayAnimation'), {
            from: that.dom.$('waveFlat'),
            to: that.dom.$('waveIn')
        })
        .onStart(() => {
            show(that.dom.$('imgOverlay'));
        })
        .play();

    await new Morph({ speed: 350, ease: 'sine.inOut' })
        .add(that.dom.$('imgOverlayAnimation'), {
            to: that.dom.$('waveFlat')
        })
        .play();

    hide(that.dom.$('imgOverlay'));
    hide([$sectionInTitleReveal, $sectionInParagraphReveal]);
    Tweener.clear([$sectionInTitleReveal, $sectionInParagraphReveal], ['scaleY', 'y']);
    Tweener.clear([$sectionInTitleH, $sectionInParagraphP], 'opacity');
    Tweener.clear([$sectionInTitleH, $sectionInParagraphP], ['opacity', 'x']);

    // Show Extra
    if (hasClass(that.dom.$('extra'), that.modifiers.vInit)) {
        new Tweener({ speed: 700, ease: 'power1.inOut' })
            .add(that.dom.$('extra'), {
                opacity: { from: 0, to: 1 }
            })
            .onStart(() => {
                removeClass(that.dom.$('extra'), that.modifiers.vInit);
            })
            .play();
    }
};

/**
 * Constants
 */
Intro.SELECTOR = '.js-intro';

export default Intro;
