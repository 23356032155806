/*
 * App
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 */

// Global variables
import App from '@app/js/App';
import Sparkle from '@sparkle/js/Sparkle';

// Plugins - App
import '@app/js/plugins/intro';
import '@app/js/plugins/sidebar';

// Plugins - Reef
import '@sparkle/js/plugins/imageSmooth';
import '@sparkle/js/plugins/scrollTo';
import '@sparkle/js/plugins/scrollVibe';
import '@sparkle/js/plugins/textBanner';
import '@sparkle/js/plugins/textReveal';
import '@sparkle/js/plugins/touchable';

// Polyfills
import '@sparkle/js/polyfills/imagesResponsive';
import '@sparkle/js/polyfills/lazyload';

// Functions
import domReady from '@sparkle/js/fn/events/domReady';
import each from '@sparkle/js/helpers/collection/each';
import yieldToMain from '@sparkle/js/helpers/function/yieldToMain';
import has from '@sparkle/js/helpers/object/has';
import keys from '@sparkle/js/helpers/object/keys';

// Defaults - App
const defaults = {

    // Plugins
    plugins: {}
};

// Plugins
const plugins = {

    // App
    intro: {
        yield: false
    },
    sidebar: {
        yield: true
    },

    // Sparkle
    imageSmooth: {
        yield: true
    },
    scrollTo: {
        yield: true
    },
    scrollVibe: {
        yield: false
    },
    textBanner: {
        yield: true
    },
    textReveal: {
        yield: false
    },
    touchable: {
        yield: true
    }
};

// DOM Ready
await domReady();

// Start App plugins
each(keys(App.plugins), key => {
    if (has(plugins, key) && !plugins[key].yield) {
        App.plugins[key](defaults.plugins[key] || {});
        delete plugins[key];
    }
});

// Start Sparkle plugins
each(keys(Sparkle.plugins), key => {
    if (has(plugins, key) && !plugins[key].yield) {
        Sparkle.plugins[key](defaults.plugins[key] || {});
        delete plugins[key];
    }
});

// Start App plugins - Yield
each(keys(App.plugins), async key => {
    if (has(plugins, key)) {
        await yieldToMain();
        App.plugins[key](defaults.plugins[key] || {});
    }
});

// Start Sparkle plugins - Yield
each(keys(Sparkle.plugins), async key => {
    if (has(plugins, key)) {
        await yieldToMain();
        Sparkle.plugins[key](defaults.plugins[key] || {});
    }
});
