/*
 * Main variable for the application.
 *
 * @author Christophe Meade
 * @copyright 2020-present Christophe Meade
 */

const App = {

    // Plugins
    plugins: {}
};

export default App;
