import attrOption from './.internal/options/attrOption';
import EventsManager from './EventsManager';
import ScrollVibe from './ScrollVibe';
import Tweener from './Tweener';
import hasClass from '../fn/attributes/hasClass';
import appendChild from '../fn/manipulation/appendChild';
import create from '../fn/manipulation/create';
import empty from '../fn/manipulation/empty';
import html from '../fn/manipulation/html';
import selectAll from '../fn/select/selectAll';
import each from '../helpers/collection/each';
import parseBool from '../helpers/lang/parseBool';
import setProps from '../helpers/object/setProps';
import trim from '../helpers/string/trim';
import className from '../helpers/utils/className';

/*
 * TextBanner functionalities
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 *
 * @param {Node} el
 * @param {Object} options
 */
const TextBanner = function (el, options) {
    const that = this;

    options = options || {};

    // Options
    setProps(options, {
        selector: TextBanner.SELECTOR,

        // Settings
        autostart: TextBanner.AUTOSTART,
        speed: TextBanner.SPEED
    });

    // Settings
    options = attrOption(options, el, ['speed', 'autostart'], options.selector);
    options.autostart = parseBool(options.autostart);

    // Selectors
    that.selectors = {
        item: `${options.selector}__item`
    };

    // Global variables
    that.el = el;
    that.options = options;
    that.events = new EventsManager();
    that.vibes = [];

    // Init
    if (hasClass(that.el, 'v--js')) {
        that.init();
    }
};

/**
 * Init
 */
TextBanner.prototype.init = function () {
    const that = this;

    // Capture the content & emty the element
    const content = trim(html(that.el));
    empty(that.el);

    that.tweenScroll = null;

    // Duplicate the content inside wrappers. The content is duplicated 2 times in
    // order to ensure that it is wide enought to cover all screen sizes
    appendChild(that.el, create('div', { html: content, classNames: className(that.selectors.item) }));
    appendChild(that.el, create('div', { html: content, classNames: className(that.selectors.item) }));

    // Listener - Show
    that.events.once(that.el, 'handleShow', () => {
        that.events.once(that.el, 'onShow', () => {
            that.events.trigger(that.el, 'handleAnimate');
        });
        that.show();
    });

    // Listener - Animate
    that.events.once(that.el, 'handleAnimate', () => {
        that.animate();
    });

    // Autostart
    if (that.options.autostart) {
        that.events.trigger(that.el, 'handleAnimate');
    }
};

/**
 * Show
 */
TextBanner.prototype.show = async function () {
    const that = this;

    // Fct - Init
    const init = async () => {
        Tweener.set(that.el, { opacity: 1 });
        return;
    };

    // Fct - Reveal
    const reveal = async () => {
        await new Tweener({ speed: 600, ease: 'power1.inOut' })
            .add(that.el, {
                scaleY: { from: 0, to: 1 },
                y: { from: '50%', to: 1 }
            })
            .play();

        return;
    };

    // Fct - Done
    const done = async () => {
        that.events.trigger(that.el, 'onShow');
        return;
    };

    // Init & proceed
    await init();
    await reveal();
    done();
};

/**
 * Animate
 */
TextBanner.prototype.animate = function () {
    const that = this;

    // Fct - Animate
    const animate = async () => {

        // Animate all the children at the time
        that.tweenScroll = new Tweener({ speed: that.options.speed, ease: 'none' });
        each(selectAll(that.selectors.item, that.el), el => {
            that.tweenScroll.add(el, {
                x: { from: 0, to: '-100%' }
            });
        });
        await that.tweenScroll.play();
        animate();
    };

    // Listener - Pause
    that.events.on(that.el, 'handlePause', () => {
        if (that.tweenScroll) {
            that.tweenScroll.pause(true);
        }
    });

    // Listener - Rewind
    that.events.on(that.el, 'handleRewind', () => {
        if (that.tweenScroll) {
            that.tweenScroll.progress(0);
        }
    });

    // Listener - Play
    that.events.on(that.el, 'handlePlay', () => {
        if (!that.tweenScroll) {
            animate();
        } else {
            that.tweenScroll.pause(false);
        }
    });

    // ScrollVibe - Play/Pause
    that.vibes.push(new ScrollVibe(that.el, {
        do: [
            {
                start: 'top bottom',
                end: 'bottom top',
                event: {
                    on: 'handlePlay',
                    off: 'handlePause'
                }
            }
        ]
    }));
};

/**
 * Destroy
 */
TextBanner.prototype.destroy = function () {
    this.events.destroy();
    each(this.vibes, vibe => {
        vibe.destroy();
    });
};

/**
 * Constants
 */
TextBanner.SELECTOR = '.js-textBanner';
TextBanner.AUTOSTART = true;
TextBanner.SPEED = 17500;

export default TextBanner;
