import addClass from '@sparkle/js/fn/attributes/addClass';
import hide from '@sparkle/js/fn/attributes/hide';
import removeClass from '@sparkle/js/fn/attributes/removeClass';
import show from '@sparkle/js/fn/attributes/show';
import scrollLock from '@sparkle/js/fn/browser/scrollLock';
import select from '@sparkle/js/fn/select/select';
import selectAll from '@sparkle/js/fn/select/selectAll';
import each from '@sparkle/js/helpers/collection/each';
import setProps from '@sparkle/js/helpers/object/setProps';
import DomManager from '@sparkle/js/utilities/DomManager';
import EventsManager from '@sparkle/js/utilities/EventsManager';
import Morph from '@sparkle/js/utilities/Morph';
import Tweener from '@sparkle/js/utilities/Tweener';

/*
 * Sidebar functionalities
 *
 * @author Christophe Meade
 * @copyright 2020-present Christophe Meade
 *
 * @param {Node} el
 * @param {Object} options
 */
const Sidebar = function (el, options) {
    const that = this;

    // Options
    setProps(options, {
        selector: Sidebar.SELECTOR
    });

    // Modifiers
    that.modifiers = {
        vHover: 'v--hover',
        vCross: 'v--cross',
        vMenu: 'v--menu',
        vWhite: 'v--white'
    };

    // Global variables
    that.el = el;
    that.options = options;
    that.events = new EventsManager();

    // DOM
    that.dom = new DomManager();
    that.dom.set({
        hamburger: () => select(`${options.selector}__hamburger`, el),
        overlay: () => select(`${options.selector}__overlay`, el),
        bg: () => select(`${options.selector}__bg`, el),
        bgAnimation: () => select(`${options.selector}__bg-animation`, that.dom.$('bg')),
        bgWave: () => select(`${options.selector}__bg-wave`),
        bgWaveFlat: () => select('#sidebar-wave-flat', that.dom.$('bgWave')),
        bgWaveCurveHide: () => select('#sidebar-wave-hide', that.dom.$('bgWave')),
        bgWaveCurveShow: () => select('#sidebar-wave-show', that.dom.$('bgWave')),
        toggles: () => selectAll(`${options.selector}__toggle`),
        menu: () => select(`${options.selector}__menu`, el),
        menuItems: () => selectAll(`${options.selector}__menu-item`, el),
        logo: () => select(`${options.selector}__logo`, el),
        separator: () => select(`${options.selector}__menu-separator`, el)
    });

    // Init
    that.init();
};

/**
 * Init
 */
Sidebar.prototype.init = function () {
    const that = this;

    // Instance
    that.isOpen = false;
    that.isUpdating = false;

    // Listener - Device Touchable
    that.events.on(document, 'onTouchable', () => {
        that.onTouchable();
    });

    // Listener - Device Not Touchable
    that.events.on(document, 'onNoTouchable', () => {
        that.onNoTouchable();
    });

    // Toggle
    const toggle = () => {
        if (!that.isUpdating) {
            if (that.isOpen) {
                that.close();
            } else {
                that.open();
            }
        }
    };

    // Listener - Toggle
    that.events.on(that.dom.$('toggles'), 'click', e => {
        e.preventDefault();
        toggle();
    });

    // Listener - Overlay
    that.events.on(that.dom.$('overlay'), 'click', () => {
        toggle();
    });
};

/**
 * Open
 */
Sidebar.prototype.open = async function () {
    const that = this;

    // Instance
    that.isOpen = true;
    that.isUpdating = true;

    // Scroll lock
    scrollLock(true);

    // Hide menu items
    const tweenerMenuItems = new Tweener();
    each(that.dom.$('menuItems'), ($menuItem, i) => {
        Tweener.set($menuItem, { opacity: 0 });
        tweenerMenuItems.add($menuItem, {
            x: { from: 50, to: 0, speed: 275, delay: i * 50, ease: 'power3.out' },
            opacity: { from: 0, to: 1, speed: 275, delay: i * 50, ease: 'power1.out' }
        });
    });

    // Hide separator
    const tweenerSeparator = new Tweener({ speed: 250, ease: 'power2.in' });
    Tweener.set(that.dom.$('separator'), { scaleX: 0, x: '-50%' });
    tweenerSeparator.add(that.dom.$('separator'), {
        scaleX: { to: 1 },
        x: { to: '0%' }
    });

    // Show Overlay
    new Tweener()
        .add(that.dom.$('overlay'), {
            opacity: { from: 0, to: 1, speed: 700, ease: 'power2.inOut' }
        })
        .onStart(() => {
            show(that.dom.$('overlay'));
        })
        .play();

    // Show Navigation
    addClass([that.dom.$('toggles'), that.dom.$('menu')], that.modifiers.vMenu);
    addClass([that.dom.$('logo'), that.dom.$('hamburger')], that.modifiers.vWhite);

    // Morph
    new Morph({ speed: 250, ease: 'sine.inOut' })
        .add(that.dom.$('bgAnimation'), {
            from: that.dom.$('bgWaveFlat'),
            to: that.dom.$('bgWaveCurveShow')
        })
        .add(that.dom.$('bgAnimation'), {
            to: that.dom.$('bgWaveFlat')
        }, true)
        .play();

    // Scale
    await new Tweener({ speed: 500, ease: 'power3.inOut' })
        .add(that.dom.$('bgAnimation'), {
            scaleX: { from: 0, to: 1 }
        })
        .onStart(() => {
            show(that.dom.$('bg'));
        })
        .play();

    // Show Items
    addClass(that.dom.$('hamburger'), that.modifiers.vCross);
    tweenerSeparator
        .play()
        .then(() => {
            Tweener.clear(that.dom.$('separator'), ['scaleX', 'x']);
        });

    await tweenerMenuItems.play();

    each(that.dom.$('menuItems'), $menuItem => {
        Tweener.clear($menuItem, ['y', 'opacity']);
    });

    that.isUpdating = false;
};

/**
 * Close
 */
Sidebar.prototype.close = async function () {
    const that = this;

    // Instance
    that.isOpen = false;
    that.isUpdating = true;

    // Hide separator
    new Tweener({ speed: 250, ease: 'power2.in' })
        .add(that.dom.$('separator'), {
            scaleX: { to: 0 },
            x: { to: '-50%' }
        })
        .play();

    removeClass(that.dom.$('hamburger'), that.modifiers.vCross);

    // Shide Overlay
    new Tweener()
        .add(that.dom.$('overlay'), {
            opacity: { to: 0, speed: 700, delay: 500, ease: 'power2.inOut' }
        })
        .play()
        .then(() => {
            hide(that.dom.$('overlay'));
        });

    // Hide menu items
    const tweenerMenuItems = new Tweener();
    each(that.dom.$('menuItems'), ($menuItem, i) => {
        tweenerMenuItems.add($menuItem, {
            y: { to: -10, speed: 150, delay: ((that.dom.$('menuItems').length - i) * 100), ease: 'power2.in' },
            opacity: { to: 0, speed: 150, delay: ((that.dom.$('menuItems').length - i) * 100), ease: 'power2.in' }
        });
    });

    await tweenerMenuItems.play();

    // Morph
    new Morph({ speed: 250, ease: 'sine.inOut' })
        .add(that.dom.$('bgAnimation'), {
            from: that.dom.$('bgWaveFlat'),
            to: that.dom.$('bgWaveCurveHide')
        })
        .add(that.dom.$('bgAnimation'), {
            to: that.dom.$('bgWaveFlat')
        }, true)
        .play();

    // Scale
    await new Tweener({ speed: 500, ease: 'power3.inOut' })
        .add(that.dom.$('bgAnimation'), {
            scaleX: { to: 0 }
        })
        .play();

    hide(that.dom.$('bg'));

    // Show Navigation
    removeClass([that.dom.$('toggles'), that.dom.$('menu')], that.modifiers.vMenu);
    removeClass([that.dom.$('logo'), that.dom.$('hamburger')], that.modifiers.vWhite);

    // Scroll lock
    scrollLock(false);

    // Reset MenuItems
    each(that.dom.$('menuItems'), $menuItem => {
        Tweener.clear($menuItem, ['y', 'opacity']);
    });

    that.isUpdating = false;
};

/**
 * Device - No Touchable
 */
Sidebar.prototype.onNoTouchable = function () {
    const that = this;

    that.events.on(that.dom.$('toggles'), 'mouseover', e => {
        addClass(that.dom.$('hamburger'), that.modifiers.vHover);
    });

    that.events.on(that.dom.$('toggles'), 'mouseout', e => {
        removeClass(that.dom.$('hamburger'), that.modifiers.vHover);
    });
};

/**
 * Device - Touchable
 */
Sidebar.prototype.onTouchable = function () {
    const that = this;

    // Remove listeners
    that.events.off(that.dom.$('toggles'), ['mouseover', 'mouseout']);
};

/**
 * Constants
 */
Sidebar.SELECTOR = '.js-sidebar';

export default Sidebar;
